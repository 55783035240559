@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap");

.input-phone,
.input-date,
.input-masked {
  font-family: "Courier Prime", monospace;
}

.form-container {
  padding: 0 20px;
  margin: 10px auto 30px;
  width: 95%;
  max-width: 500px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}

form {
  padding: 20px;
  color: #335;
}

input,
select,
option {
  border: 1px solid #aaa;
  border-radius: 5px;
  color: #335;
  font-size: 18px;
  font-weight: 300;
  height: 50px;
  padding: 0 8px;
  margin-bottom: 30px;
  vertical-align: middle;
}

input:focus {
  border: 1px solid #335;
  outline: none;
}

input[type="text"] {
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
}

form label {
  margin: 10px 10px 10px 0;
  line-height: 20px;
}

.form-div-checkbox,
.form-div-radio {
  margin-bottom: 30px;
}

.form-div-checkbox label {
  display: inline;
}
.form-div-submit {
  text-align: right;
}

.input-error {
  background: rgb(255, 195, 195);
}

.form-thankyou {
  margin-top: 100px;
}

.price-adjust input[type="range"] {
  margin: 0;
  width: 100%;
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked),
input[type="radio"]:checked,
input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label,
input[type="radio"]:checked + label,
input[type="radio"]:not(:checked) + label {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  line-height: 20px;
  cursor: pointer;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  border-radius: 2px;
}

input[type="radio"]:checked + label:before,
input[type="radio"]:not(:checked) + label:before {
  border-radius: 100%;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  left: 2px;
  top: 4px;
  width: 12px;
  height: 6px;
  border-radius: 1px;
  border-left: 4px solid rgb(53, 53, 247);
  border-bottom: 4px solid rgb(53, 53, 247);
  -webkit-transform: rotate(-55deg);
  -moz-transform: rotate(-55deg);
  -o-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
}

input[type="radio"]:checked + label:after,
input[type="radio"]:not(:checked) + label:after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: rgb(53, 53, 247);
}

input[type="checkbox"]:not(:checked) + label:after,
input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after,
input[type="radio"]:checked + label:after {
  opacity: 1;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: linear-gradient(
    40deg,
    rgb(3, 58, 109) 20%,
    rgb(14, 107, 131) 80%
  );
  border-radius: 5px;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 35px;
  border-radius: 20%;
  border: 1px solid rgb(255, 255, 255);
  background: rgb(53, 53, 247);
  cursor: grab;
  box-shadow: 3px 3px 10px 0 #0000006c;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

@media (max-width: 800px) {
  .form-container {
    margin-top: 100px;
  }
}
