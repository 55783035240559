@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root,
.page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

body,
.purple {
  background: linear-gradient(
    40deg,
    rgb(41, 41, 124) 20%,
    rgb(126, 71, 252) 80%
  );
}
.odd,
.main-menu,
.footer {
  background: linear-gradient(
    40deg,
    rgb(3, 58, 109) 20%,
    rgb(14, 107, 131) 80%
  );
}

.content {
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
}

.logo {
  max-width: 200px;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.logo svg {
  fill: #fcfcfc;
}

header {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

h1 {
  font-size: 2em;
  font-weight: 500;
}
h2 {
  font-size: 1.5em;
  margin-top: 20px;
  font-weight: 500;
}
h3 {
  font-size: 1.2em;
  margin-top: 20px;
  font-weight: 500;
  display: inline-block;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

ul {
  list-style: disc;
  padding-left: 20px;
}

p {
  line-height: 1.6;
}

.main-menu {
  color: #fff;
  padding: 0;
  margin-top: 20px;
}

.main-menu a {
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
  font-weight: 300;
}

.main-menu a div {
  padding: 20px 10px;
  transition: background-color 0.3s;
  text-align: center;
}
.main-menu a div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.odd {
  border-radius: 300px 800px 0px 0px / 60px 40px 0px 0px;
}

.footer {
  border-radius: 300px 800px 0px 0px / 60px 40px 0px 0px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100px;
  margin-top: auto;
}

button {
  width: 200px;
  height: 50px;
  line-height: 50px;
  padding-bottom: 5px;
  margin-right: 0;
  background: #339;
  font-size: 1.2em;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
}

table {
  width: 100%;
  border: 0;
  padding: 0;
  border-spacing: 0;
}
td {
  border: 0;
  padding: 5px 10px;
}

tr {
  background-color: #c0c0c088;
}
.even {
  background-color: #33333388;
}

.price {
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
}

.price-adjust div {
  padding-bottom: 10px;
}

@media (min-width: 800px) {
  .main-menu .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
  }
}
