.content-manager {
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.container-block-manager {
  background-color: rgba(255, 255, 255, 0.616);
  width: 100%;
  padding: 15px;
}

.manager-menu {
  width: 150px;
  min-height: 400px;
  padding: 20px;
}

.manager-menu a {
  text-decoration: none;
  color: white;
}

.manager-menu ul {
  list-style: none;
  padding-left: 10px;
}

.manager-menu li {
  padding-top: 13px;
}

.manager-menu a:last-child {
  line-height: 60px;
}

.workers-cards {
  display: flex;
  color: #335;
}

.workers-card {
  width: 150px;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin: 10px;
}

.worker-card-photo-border {
  width: 110px;
  height: 110px;
  margin: 10px auto;
  border-radius: 60px;
  position: relative;
}

.worker-card-photo {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 50px;
  top: 5px;
  left: 5px;
  position: absolute;
}

.worker-card-name {
  text-align: center;
}
.worker-card-set-shift {
  font-size: 12px;
  width: 100%;
  height: 30px;
  line-height: 30px;
}
.worker-card-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.worker-card-buttons div {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.employers-cards {
  display: flex;
  color: #335;
}

.employers-card {
  display: flex;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin: 10px;
}
.employers-card > div {
  margin: 0 5px;
}

.employer-card-logo-border {
  width: 60px;
  height: 60px;
  margin: 0;
  border-radius: 30px;
  position: relative;
}

.employer-card-logo {
  width: 50px;
  height: 50px;
  background-color: #ccc;
  border-radius: 25px;
  top: 5px;
  left: 5px;
  position: absolute;
}

.employer-card-name {
  min-width: 120px;
  text-align: center;
  line-height: 20px;
}

@media (max-width: 800px) {
  .content-manager {
    margin-top: 100px;
  }
}
