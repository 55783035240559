.lang-menu a {
  text-decoration: none;
  color: white;
}

.lang-menu ul {
  list-style: none;
}
.lang-menu li {
  display: inline;
  padding: 20px;
}
